import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.1_@babel+core@7.24.7_@opentelemetry+api@1.9.0_@playwright+test@1.43.1_react-dom@18._tusrgt3hdqrsg2eedu5u4qjgse/node_modules/next/font/local/target.css?{\"path\":\"src/assets/fonts/default-fonts.ts\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"src\":[{\"path\":\"./Montserrat/Montserrat-Regular.woff2\",\"style\":\"normal\",\"weight\":\"400\"},{\"path\":\"./Montserrat/Montserrat-Bold.woff2\",\"style\":\"normal\",\"weight\":\"700\"},{\"path\":\"./Montserrat/Montserrat-ExtraBold.woff2\",\"style\":\"normal\",\"weight\":\"800\"}],\"variable\":\"--font-body\"}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.1_@babel+core@7.24.7_@opentelemetry+api@1.9.0_@playwright+test@1.43.1_react-dom@18._tusrgt3hdqrsg2eedu5u4qjgse/node_modules/next/font/local/target.css?{\"path\":\"src/assets/fonts/default-fonts.ts\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"src\":[{\"path\":\"./PublicoHeadline/PublicoHeadline-Light-Web.woff2\",\"style\":\"normal\",\"weight\":\"300\"},{\"path\":\"./PublicoHeadline/PublicoHeadline-LightItalic-Web.woff2\",\"style\":\"italic\",\"weight\":\"300\"},{\"path\":\"./PublicoHeadline/PublicoHeadline-Roman-Web.woff2\",\"style\":\"normal\",\"weight\":\"400\"},{\"path\":\"./PublicoHeadline/PublicoHeadline-Italic-Web.woff2\",\"style\":\"italic\",\"weight\":\"400\"},{\"path\":\"./PublicoHeadline/PublicoHeadline-Medium-Web.woff2\",\"style\":\"normal\",\"weight\":\"500\"},{\"path\":\"./PublicoHeadline/PublicoHeadline-MediumItalic-Web.woff2\",\"style\":\"italic\",\"weight\":\"500\"},{\"path\":\"./PublicoHeadline/PublicoHeadline-Bold-Web.woff2\",\"style\":\"normal\",\"weight\":\"700\"},{\"path\":\"./PublicoHeadline/PublicoHeadline-BoldItalic-Web.woff2\",\"style\":\"italic\",\"weight\":\"700\"},{\"path\":\"./PublicoHeadline/PublicoHeadline-Extrabold-Web.woff2\",\"style\":\"normal\",\"weight\":\"800\"},{\"path\":\"./PublicoHeadline/PublicoHeadline-ExtraboldItalic-Web.woff2\",\"style\":\"italic\",\"weight\":\"800\"},{\"path\":\"./PublicoHeadline/PublicoHeadline-Black-Web.woff2\",\"style\":\"normal\",\"weight\":\"900\"},{\"path\":\"./PublicoHeadline/PublicoHeadline-BlackItalic-Web.woff2\",\"style\":\"italic\",\"weight\":\"900\"}],\"variable\":\"--font-heading\"}],\"variableName\":\"publicoHeadlineWeb\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.1_@babel+core@7.24.7_@opentelemetry+api@1.9.0_@playwright+test@1.43.1_react-dom@18._tusrgt3hdqrsg2eedu5u4qjgse/node_modules/next/font/local/target.css?{\"path\":\"src/assets/fonts/monitor-fonts.ts\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"src\":[{\"path\":\"./Flama/Flama-Book.woff2\",\"style\":\"normal\",\"weight\":\"400\"},{\"path\":\"./Flama/Flama-Medium.woff2\",\"style\":\"normal\",\"weight\":\"500\"},{\"path\":\"./Flama/Flama-Bold.woff2\",\"style\":\"normal\",\"weight\":\"700\"}],\"variable\":\"--font-body\"}],\"variableName\":\"flama\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.1_@babel+core@7.24.7_@opentelemetry+api@1.9.0_@playwright+test@1.43.1_react-dom@18._tusrgt3hdqrsg2eedu5u4qjgse/node_modules/next/font/local/target.css?{\"path\":\"src/assets/fonts/monitor-fonts.ts\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"src\":[{\"path\":\"./Capitolium/Capitolium-Regular.woff2\",\"style\":\"normal\",\"weight\":\"400\"},{\"path\":\"./Capitolium/Capitolium-Semibold.woff2\",\"style\":\"normal\",\"weight\":\"500\"},{\"path\":\"./Capitolium/Capitolium-Bold.woff2\",\"style\":\"normal\",\"weight\":\"700\"}],\"variable\":\"--font-heading\"}],\"variableName\":\"capitolium\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.1_@babel+core@7.24.7_@opentelemetry+api@1.9.0_@playwright+test@1.43.1_react-dom@18._tusrgt3hdqrsg2eedu5u4qjgse/node_modules/next/font/local/target.css?{\"path\":\"src/assets/fonts/tabloid-fonts.ts\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"src\":[{\"path\":\"./AtlasGrotesk/AtlasGrotesk-ThinItalic-Web.woff2\",\"style\":\"italic\",\"weight\":\"100\"},{\"path\":\"./AtlasGrotesk/AtlasGrotesk-Thin-Web.woff2\",\"style\":\"normal\",\"weight\":\"100\"},{\"path\":\"./AtlasGrotesk/AtlasGrotesk-LightItalic-Web.woff2\",\"style\":\"italic\",\"weight\":\"300\"},{\"path\":\"./AtlasGrotesk/AtlasGrotesk-Light-Web.woff2\",\"style\":\"normal\",\"weight\":\"300\"},{\"path\":\"./AtlasGrotesk/AtlasGrotesk-RegularItalic-Web.woff2\",\"style\":\"italic\",\"weight\":\"400\"},{\"path\":\"./AtlasGrotesk/AtlasGrotesk-Regular-Web.woff2\",\"style\":\"normal\",\"weight\":\"400\"},{\"path\":\"./AtlasGrotesk/AtlasGrotesk-MediumItalic-Web.woff2\",\"style\":\"italic\",\"weight\":\"500\"},{\"path\":\"./AtlasGrotesk/AtlasGrotesk-Medium-Web.woff2\",\"style\":\"normal\",\"weight\":\"500\"},{\"path\":\"./AtlasGrotesk/AtlasGrotesk-BoldItalic-Web.woff2\",\"style\":\"italic\",\"weight\":\"700\"},{\"path\":\"./AtlasGrotesk/AtlasGrotesk-Bold-Web.woff2\",\"style\":\"normal\",\"weight\":\"700\"},{\"path\":\"./AtlasGrotesk/AtlasGrotesk-BlackItalic-Web.woff2\",\"style\":\"italic\",\"weight\":\"900\"},{\"path\":\"./AtlasGrotesk/AtlasGrotesk-Black-Web.woff2\",\"style\":\"normal\",\"weight\":\"900\"}],\"variable\":\"--font-heading\"}],\"variableName\":\"atlasGrotesk\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.1_@babel+core@7.24.7_@opentelemetry+api@1.9.0_@playwright+test@1.43.1_react-dom@18._tusrgt3hdqrsg2eedu5u4qjgse/node_modules/next/font/local/target.css?{\"path\":\"src/assets/fonts/tabloid-fonts.ts\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"src\":[{\"path\":\"./Montserrat/Montserrat-Regular.woff2\",\"style\":\"normal\",\"weight\":\"400\"},{\"path\":\"./Montserrat/Montserrat-Bold.woff2\",\"style\":\"normal\",\"weight\":\"700\"},{\"path\":\"./Montserrat/Montserrat-ExtraBold.woff2\",\"style\":\"normal\",\"weight\":\"800\"}],\"variable\":\"--font-body\"}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Link"] */ "/app/src/components/Link/Link.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Logo"] */ "/app/src/components/Logo/Logo.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Copyright"] */ "/app/src/components/MinimalFooter/components/Copyright/Copyright.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ManageConsentButton"] */ "/app/src/components/MinimalFooter/components/ManageConsentButton/ManageConsentButton.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Translation"] */ "/app/src/components/Translation/Translation.component.tsx");
